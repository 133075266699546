import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment/environment";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ConfirmAndSendService {
  apiUrl: string = environment.backendHost;
  isCheckedBrand = new Subject<any>();
  constructor(private http: HttpClient) {}

  updateDeposit(data: any) {
    return this.http.put(
      `${this.apiUrl}api/v1/settings/update_deposit_confirm`,
      data
    );
  }
  updateWithdraw(data: any) {
    return this.http.put(
      `${this.apiUrl}api/v1/settings/update_withdraw_confirm`,
      data
    );
  }
  updateDepositPending(data: any) {
    return this.http.put(
      `${this.apiUrl}api/v1/settings/update_deposit_pending_accept`,
      data
    );
  }
  updateStopDeposit(data: any) {
    return this.http.put(`${this.apiUrl}api/v1/settings/stop_deposit`, data);
  }
  updateStopWithdrawal(data: any) {
    return this.http.put(`${this.apiUrl}api/v1/settings/stop_withdraw`, data);
  }
  updateAutoWithdrawal(data: any) {
    return this.http.put(
      `${this.apiUrl}api/v1/settings/enable_auto_withdraw`,
      data
    );
  }

  get(data?: any) {
    return this.http.get(`${this.apiUrl}/api/v1/settings/get_admin_setting`);
  }
  updateJionex(data: any) {
    return this.http.put(
      `${this.apiUrl}api/v1/settings/update_logo_status`,
      data
    );
  }
  getJionexStatus() {
    return this.http.get(`${this.apiUrl}/api/v1/settings/logo_setting`);
  }
  sendBrand(data: any) {
    this.isCheckedBrand.next({ data: data });
  }
  getBrand() {
    return this.isCheckedBrand.asObservable();
  }
}
